import React, { type ReactNode } from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"

import { TooltipQuestion } from "./Tooltip/Tooltip"

import { ITEMS } from "./helpers"

import * as styles from "./moreBenefitsStickerPay.module.scss"

type MoreBenefitsRedesignProps = {
  title?: ReactNode
  orderNum?: string
}

export const MoreBenefitsStickerPay = ({
  title = "Преимущества платежного стикера PAY",
  orderNum,
}: MoreBenefitsRedesignProps) => (
    <section className={styles.section} id="moreBenefits" data-exclude={orderNum}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.textBlock}>
            <div className={styles.title}>{title}</div>
          </div>
          <div className={styles.wrap}>
            {ITEMS.map(({ Img, alt, title: itemTitle, tooltipHint, hintClassName }, i) => (
              <div key={i} className={clsx(styles.item, styles[`item${i + 1}`])}>
                <div className={styles.itemText}>
                  <p>{itemTitle}</p>
                  {tooltipHint && (
                    <div className={styles.tooltipQuestion}>
                      <TooltipQuestion text={tooltipHint} hintClassName={hintClassName} />
                    </div>
                  )}
                </div>
                <Img alt={alt} className={styles.img} />
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  )
