import { decode } from "html-entities"

import { IndexPageData } from "../interfaces/pageProps"

export function getIndexPageData({ admin }: IndexPageData) {
  const ligalOrder = admin?.order?.ligal?.text
  const ligalGethalva = admin?.getHalva?.ligal?.text

  const phonesOrder =
    Array.isArray(admin?.order?.phones) && admin?.order!.phones?.length > 0
      ? admin?.order?.phones
      : undefined

  const phonesGethalva =
    Array.isArray(admin?.getHalva?.phones) && admin?.getHalva!.phones?.length > 0
      ? admin?.getHalva?.phones
      : undefined

  const noIndex = admin?.order?.notIndex
  const isHiddenPageGetHalva = admin?.getHalva?.notIndex

  const result = {
    // чтобы сработало значение по-умолчанию нужен undefined вместо null
    ligalOrder: ligalOrder ? decode(ligalOrder) : undefined,
    ligalGethalva: ligalGethalva ? decode(ligalGethalva) : undefined,
    phonesOrder: phonesOrder || undefined,
    phonesGethalva: phonesGethalva || undefined,
    isHiddenPageGetHalva,
    noIndex,
  }

  return result
}
