import React from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"
import * as styles from "./banner.module.scss"

import { TypeBannerProps } from "../helpers"
import { useObserver } from "../../../hooks/useObserver"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

const buttonText = "Оформить"

const bannerDescription = `
  — До 24 мес. рассрочки на покупки у партнёров
  <br />
  — Кэшбэк до 10%
  <br />
  — До 17% на остаток по карте 
`
export function Banner({ orderNum, description = bannerDescription }: TypeBannerProps) {
  const isVisible = useObserver(["app", "calculator", "reviewCard"])

  return (
    <>
      <section data-exclude={orderNum} id="banner">
        <Container className={styles.container}>
          <div className={styles.secondContainer}>
            <Img alt="bg image" className={styles.img} />
            <div className={styles.text}>
              <h1>Платежный стикер PAY</h1>
              <div className={styles.descBlock} dangerouslySetInnerHTML={{ __html: description }} />
              <Button onClick={() => handleClickBanner("click_cta")} className={styles.btn}>
                {buttonText}
              </Button>
            </div>
          </div>
        </Container>
      </section>
      <div className={clsx(styles.btnBlock, { [styles.hideMobBlock]: isVisible })}>
        <Container>
          <Button onClick={() => handleClickBanner("sticky")} className={styles.mobBtn}>
            {buttonText}
          </Button>
        </Container>
      </div>
    </>
  )
}
