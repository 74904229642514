import React, { ReactNode, useEffect, useState } from "react"
import Tooltip from "@material-ui/core/Tooltip"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import { withStyles } from "@material-ui/styles"
import { Theme, Zoom } from "@material-ui/core"
import clsx from "clsx"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import * as styles from "./Tooltip.module.scss"
import { ExclamationPoint } from "../../../images/svg/ExclamationPoint"

export const Close = () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m8.94 8 4.2-4.193a.67.67 0 1 0-.947-.947L8 7.06l-4.193-4.2a.67.67 0 0 0-.947.947L7.06 8l-4.2 4.193a.667.667 0 0 0 .217 1.093.666.666 0 0 0 .73-.146L8 8.94l4.193 4.2a.665.665 0 0 0 .947 0 .666.666 0 0 0 0-.947L8.94 8Z"
      fill="currentColor"
    />
  </svg>
)

interface ITooltipQuestion {
  text: ReactNode
  hintClassName: any
}

export const TooltipQuestion = ({ text, hintClassName }: ITooltipQuestion) => {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const handleScroll = () => {
      setOpen(false)
    }

    window.addEventListener("scroll", () => {
      handleScroll()
    })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleTooltipOpen = () => {
    setOpen(true)
    pushToDataLayer({ event: "buttonClick", name: "click_informer" })
  }
  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      ...hintClassName.xs,
      color: "#000",
      position: "absolute",
      pointerEvents: "auto",
      cursor: "default",
      padding: "12px 16px",
      background: "#ffffff",
      boxSizing: "border-box",
      boxShadow: " 0px 8px 14px rgba(122, 122, 122, 0.25)",
      borderRadius: 12,
      transition: " 0.3s all",
      marginTop: 16,

      [theme.breakpoints.up("sm")]: {
        ...hintClassName.sm,
      },

      [theme.breakpoints.up("md")]: {
        ...hintClassName.md,
      },

      [theme.breakpoints.up("lg")]: {
        ...hintClassName.lg,
      },
    },
  }))(Tooltip)
  return (
    <div className={clsx(styles.cardOuter)}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <HtmlTooltip
            PopperProps={{
              disablePortal: false,
            }}
            onClose={handleTooltipOpen}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <div className={styles.contentTooltip}>
                <div>
                  <ExclamationPoint />
                </div>
                <div className={styles.hintText}>{text}</div>
                <div className={styles.close} onClick={handleTooltipClose} aria-hidden="true">
                  <Close />
                </div>
              </div>
            }
            TransitionComponent={Zoom}
          >
            <div onClick={handleTooltipOpen} aria-hidden="true" className={styles.tooltipIcon}>
              <ExclamationPoint />
            </div>
          </HtmlTooltip>
        </div>
      </ClickAwayListener>
    </div>
  )
}
